import { render, staticRenderFns } from "./BaseActionCell.vue?vue&type=template&id=afd370bc&scoped=true"
import script from "./BaseActionCell.vue?vue&type=script&lang=js"
export * from "./BaseActionCell.vue?vue&type=script&lang=js"
import style0 from "./BaseActionCell.vue?vue&type=style&index=0&id=afd370bc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "afd370bc",
  null
  
)

export default component.exports